/* globals jQuery */
(function($, window){

	"use strict";

	window.bmedSecurityData = function() {

		if (!$(".cme__splash").hasClass(".cme__splash__secure")) {
			return {
				"cmeSplash": {
					"security": false
				}
			};
		}

	};

})(jQuery, window);